import React, { useEffect, useState } from 'react'
import { pure } from 'recompose'
import { dataStore, pageStore } from "state/store-zustand";

import { fetchPlacesDetails } from "components/utils/testHelpers.js"

import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'

import PlaceForm from 'components/forms/addPlaceForm'

import AuthDialog from "components/account/authDialog"
import Branding from "components/account/branding"

import '../styles/search.scss'
import 'components/forms/form.scss'

const EditBusiness = ({ data, location, params,...props }) => {
  // Page context and options
  const pageLoader = usePageLoading()
  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const isClient = pageStore((state) => state.isClient)
  const embedded = pageStore((state) => state.embedded)
  const placeID = dataStore((state) => state.placeID)
  const placeCurrent = dataStore((state) => state.placeCurrents)
  const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)

  // Form content and state
  const alreadyLoaded = placeCurrent != null
  const [isLoading, setIsLoading] = useState(!alreadyLoaded)
  const [hasError, setHasError] = useState(false)


  const handleSubmit = async (event, data) => {
    console.log('TODO: handleSubmit ', event, data);
    debugger
    //setClearForm(true)
    //setTitle('Thanks for helping us!')
    //setMessage(<p>Vibemap will include this business in our city pages, vibe guides, and other content.</p>)
  }

  if (!isClient) {
    return null
  }

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      const details = await fetchPlacesDetails(id, undefined, true)
        .catch(e => {
          console.log(`Error with place `, id, e)
          setHasError(true)
        })

      if (details) {
        setPlaceCurrent(details.data)
        setIsLoading(false)
        const place = details.data?.properties?.hotspots_place
      } else {
        setHasError(true)
        setIsLoading(false)
      }
    }

    if (placeCurrent == null) {
      fetchData(placeID)
    }

  }, [placeID])


  const PlaceFormMemo = React.memo(PlaceForm);

  return (
    <>
      <main className={`page add-event ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ? theme : ''}`}>

        <Branding />

        <AuthDialog />

        <div className='container'>
            <PlaceFormMemo
              onSubmitCallback={handleSubmit}
              showSearch={false}
              lookupEvent={false}
              updateOnly={true} />
        </div>
      </main>
    </>
  )
}

export default pure(EditBusiness);